import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import NotFound from "../components/not-found"
import LocationDisabled from "../images/location-disabled.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageTitle
      title="Not Found"
      subTitle="You just hit a route that doesn't exist..."
      icon={LocationDisabled}
    />
    <NotFound />
  </Layout>
)

export default NotFoundPage
