import React from "react"
import styled from "styled-components"
import curve from "../images/curve.svg"
import { breakpoints } from "./variables"

const TitleW = styled.div`
  padding-top: 7rem;
  padding-bottom: 10vw;
  position: relative;
  color: ${props => props.theme.palette.primary.main};
  background-color: ${props => props.theme.palette.datamap.main};
  margin-bottom: 2rem;
  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 20rem;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.07;
    @media screen and (max-width: ${breakpoints.sm}) {
      width: 50%;
      left: 50%;
      top: 60%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  &::after {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 2vw;
    background-image: url(${curve});
    background-position-x: center;
    background-size: 110vw;
  }
`
const SubTitle = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 1.5rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`
const Date = styled.p`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`
const Title = styled.h1`
  text-align: center;
  margin: 0;
  font-size: 3rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
  }
`

const PageTitle = ({ title, subTitle, icon, date }) => {
  return (
    <TitleW img={icon}>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {date && (
        <Date>
          Last update on: <b>{date}</b>
        </Date>
      )}
    </TitleW>
  )
}

export default PageTitle
