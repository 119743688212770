import React from "react"
import styled from "styled-components"

const NotFoundW = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
`

const NotFound = () => {
  return <NotFoundW></NotFoundW>
}

export default NotFound
